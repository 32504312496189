import Swiper from "swiper";
import { Scrollbar, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "./hoa-collection-slider.css";

class HoaCollectionSlider extends HTMLElement {
	public constructor() {
		super();

		const nextButton = this.querySelector<HTMLElement>(".swiper-button-next");
		const prevButton = this.querySelector<HTMLElement>(".swiper-button-prev");

		new Swiper(this.querySelector<HTMLElement>(".swiper"), {
			modules: [Scrollbar, Navigation],
			direction: "horizontal",
			loop: false,
			slidesPerView: 1,
			spaceBetween: 30,
			mousewheel: {
				forceToAxis: true,
			},
			breakpoints: {
				750: {
					slidesPerView: 3,
				},
				990: {
					slidesPerView: 4,
				},
			},

			// Navigation arrows
			navigation: {
				nextEl: nextButton,
				prevEl: prevButton,
			},

			// And if we need scrollbar
			scrollbar: {
				el: this.querySelector<HTMLElement>(".swiper-scrollbar"),
			},
		});

		new ResizeObserver((event) => {
			const firstImage = this.querySelector("._hoa-lazy-image");
			if (firstImage) {
				const imageHeight = firstImage.getBoundingClientRect().height;
				this.style.setProperty("--image-height", `${imageHeight}px`);
			}
		}).observe(this.querySelector("div.swiper"));
	}
}

customElements.define("hoa-collection-slider", HoaCollectionSlider);
